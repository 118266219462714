import { useAuth0 } from "@auth0/auth0-react";
import { APIClient } from "api/apiClient";
import { APIKeyResponse } from "api/types";
import { AUTH0_AUDIENCE } from "const";
import React, { useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";

export const ConnectionSinusbot = () => {
  const { getAccessTokenSilently, getAccessTokenWithPopup } = useAuth0();

  const opts = {
    audience: AUTH0_AUDIENCE,
    scope: "whatsapp:managed"
  };

  const getAccessToken = async () => {
    var access_token = "";
    try {
      access_token = await getAccessTokenSilently(opts);
    } catch (e) {
      access_token = await getAccessTokenWithPopup(opts);
    }
    return access_token;
  };

  const [key, setKey] = useState<APIKeyResponse>();

  return (
    <>
      <div>
        <h3 className="text-xl  font-bold my-4 pb-2">Connect your Sinusbot</h3>
        <p>Open your Support++ config and insert your message send token. Keep it private!</p>

        <button
          className="text-sppbtn-500 font-bold  mt-5"
          onClick={async () => {
            new APIClient(await getAccessToken()).getAPIKey().then(e => setKey(e?.data));
          }}
        >
          Create new API key
        </button>
        <br />
        {key?.token !== undefined ? (
          <div className="flex">
            <p className="max-w-xl overflow-auto ">{key?.token}</p>
            <CopyToClipboard text={key?.token || ""} onCopy={() => alert("API token copied to clipboard!")}>
              <button className="text-sppbtn-500 font-bold  mt-5">Copy</button>
            </CopyToClipboard>
          </div>
        ) : null}
      </div>
    </>
  );
};
