import { useAuth0 } from "@auth0/auth0-react";
import { APIClient } from "api/apiClient";
import { LinkedResponse } from "api/types";
import { AUTH0_AUDIENCE, AUTH0_DOMAIN } from "const";
import React, { useEffect, useState } from "react";
import { Connection } from "./components/Connection";
import { ConnectionSinusbot } from "./components/ConnectionSinusbot";
import { ControllBar } from "./ControllBar";

export const Settings = () => {
  const { getAccessTokenSilently, getAccessTokenWithPopup } = useAuth0();

  const [information, setInformation] = useState<LinkedResponse>();

  const opts = {
    audience: AUTH0_AUDIENCE,
    scope: "whatsapp:managed"
  };

  const getAccessToken = async () => {
    var access_token = "";
    try {
      access_token = await getAccessTokenSilently(opts);
    } catch (e) {
      access_token = await getAccessTokenWithPopup(opts);
    }
    return access_token;
  };

  const run = async () => {
    new APIClient(await getAccessToken()).getWhatsappLinkedStatus().then(e => setInformation(e.data?.data));
  };
  useEffect(() => {
    run();
  }, []);
  //Loading
  if (information === undefined) {
    return <p>Loading (...)</p>;
  }
  return (
    <div>
      <hr />
      <h1 className="text-2xl  font-bold my-4 pb-2">Settings</h1>

      {information?.linked ? (
        <>
          <p className="text-green-800">You have connected already a phone number: +{information?.number}</p>
          <button
            className="bg-red-700 text-white rounded px-4 py-2 mt-5"
            onClick={async () => {
              new APIClient(await getAccessToken()).disconnect().then(e => {
                setInformation(undefined);
                run();
              });
            }}
          >
            Disconnect
          </button>
        </>
      ) : (
        <Connection code={information?.invite_code || ""} />
      )}
      <ConnectionSinusbot />
      <ControllBar />
    </div>
  );
};
