import { useAuth0 } from "@auth0/auth0-react";
import { LoginPage } from "pages/LoginPage";
import { Settings } from "pages/Settings";
import React from "react";
/**
 * App
 */
const App: React.FC<any> = () => {
  const { isLoading, isAuthenticated, error, user, loginWithRedirect, logout } = useAuth0();

  if (isLoading) {
    return <div>Loading...</div>;
  }
  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  return (
    <div className="flex justify-center items-center w-full min-h-screen bg-gray-200 font-nunito">
      <div className="flex flex-col justify-center items-center rounded bg-white shadow-md hover:shadow-xl transition duration-150 w-2/3">
        <div className="flex flex-grid  justify-center  bg-sppgreen-500 w-full p-4">
          <img width="80" src="https://support-pp.de/img/c3d8807d8f6921324ddaab30637a2f86137cfbe1.png" />
          <p className="text-lg font-semibold text-white pt-5 pl-2 text-2xl">
            Support++ <b>WhatsApp</b>
            <small className="bg-red-600 rounded text-white  uppercase p-1 ml-3 text-sm">beta</small>
          </p>
        </div>

        <div className=" my-4 mx-20 text-spp-500">
          <p className="text-black text-block">
            Welcome to our new WhatsApp Console. Here you can set up your whatsapp bridge for Support++. After that you
            can receive notification and tickets directly on WhatsApp.
          </p>
          <br />
          {isAuthenticated ? <Settings /> : <LoginPage />}
        </div>
      </div>
    </div>
  );
};

export { App };
