import React from "react";
import ReactDOM from "react-dom";

// Styles
import "./styles/main.css";

import { App } from "./App";
import { Auth0Provider } from "@auth0/auth0-react";
import { AUTH0_CLIENT_ID, AUTH0_DOMAIN } from "const";

const MOUNT_NODE = document.getElementById("root");

ReactDOM.render(
  <Auth0Provider
    domain={AUTH0_DOMAIN}
    clientId={AUTH0_CLIENT_ID}
    redirectUri={window.location.origin}
    scope={"whatsapp:managed"}
  >
    <App />
  </Auth0Provider>,
  MOUNT_NODE
);
