import { useAuth0 } from "@auth0/auth0-react";
import React from "react";

export const ControllBar = () => {
  const { isLoading, isAuthenticated, error, user, loginWithRedirect, logout } = useAuth0();
  return (
    <>
      <hr />
      <br />
      <div className="flex justify-content">
        <button
          className="bg-sppbtn-500 text-white rounded px-4 py-2"
          onClick={() => logout({ returnTo: window.location.origin })}
        >
          Log out {user?.email}
        </button>
      </div>
    </>
  );
};
