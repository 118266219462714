import { useAuth0 } from "@auth0/auth0-react";
import React from "react";

export const LoginPage = () => {
  const { isLoading, isAuthenticated, error, user, loginWithRedirect, logout } = useAuth0();
  return (
    <div>
      <button className="bg-sppbtn-500 text-white rounded px-4 py-2" onClick={() => loginWithRedirect()}>
        Log in
      </button>
    </div>
  );
};
