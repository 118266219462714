import { useAuth0 } from "@auth0/auth0-react";
import axios, { AxiosInstance } from "axios";

export class APIClient {
  private baseURL = "https://api.support-pp.de";
  private client: AxiosInstance;

  constructor(access_token: string) {
    this.client = axios.create({
      baseURL: process.env.APIURL || this.baseURL,
      headers: {
        Authorization: "Bearer " + access_token
      }
    });
  }

  public getWhatsappLinkedStatus(): Promise<any> {
    return this.client.get("/api/whatsapp/whatsapp-linked");
  }
  public disconnect(): Promise<any> {
    return this.client.post("/api/whatsapp/code-renew");
  }
  public getAPIKey(): Promise<any> {
    return this.client.post("/api/whatsapp/create-api-token");
  }
}
