import React from "react";
import QRCode from "qrcode.react";
import { phoneNumber, phoneNumberFormated } from "const";
export const Connection = (p: { code: string }) => {
  return (
    <>
      <h3 className="text-xl  font-bold my-4 pb-2">Connect your WhatsApp account</h3>
      <div className="grid grid-cols-2">
        <div>
          <p>automatic by scanning the QR-Code:</p>
          <QRCode
            value={`https://wa.me/${phoneNumber}/?text=!verify ${p?.code}`}
            height={"120px"}
            className="self-center"
          />
        </div>
        <div>
          <p>or manually:</p>
          1. Create a new contact with the phone number: <b>{phoneNumberFormated}</b>
          <br />
          2. Open <a href="https://www.whatsapp.com/download/">WhatsApp</a> and start a new conversation <br />
          3. To verify your number send: <code>!verify {p?.code}</code> <br />
          4. Finish. Your account is linked with WhatsApp. You can now continue with the instructions for setting up
          Sinusbot.
        </div>
      </div>
    </>
  );
};
